import logo1 from '../assets/logo1.JPG'
import '../assets/Styles.css';


export default function Hero() {
    return(
        <div className="logo-container">
            <img src={logo1} alt='' className='logo1' />
        
        </div>
    )
}